const benefits = {
  index: {
    benefits_title: 'Mis Beneficios',
    description: 'Navegue todos los beneficios y descuentos médicos que están incluidos en su suscripción.',
    featured_benefit: 'Destacado',
    popular_benefit: 'Beneficios más populares',
    other_benefit: 'Otros beneficios',
    silver_benefit: 'Beneficios',
    need_help: '¿Tiene preguntas?',
    details: 'Detalles',
    call: 'Contáctenos',
    start_chat_now: 'Abra un chat',
    call_our_support: 'Llámenos',
    start_live_chat: "Con gusto le ayudamos a solucionar todas sus dudas.",
    information: 'Acerca de este beneficio',
    hide_benefits: 'Mostrar menos',
    show_benefits: 'Mostrar más',
    book_appointment: '¿Quiere empezar a ahorrar?',
    call_service: 'Llame ya',
    faq: 'Preguntas frecuentes',
    faqs: '<p>Preguntas más</p><p>frecuentes</p>',
    which_one_do_you_want_to_learn: '¿Sobre qué beneficio le gustaría saber más?',
    learn_more: 'Aprenda más',
    benefit_card: 'Tarjeta de beneficios',
    view_card: 'Ver tarjeta',
    your_membership_card: 'Su tarjeta de descuentos',
    discount_card: 'Descuentos',
    to_receive_your_discount: 'Para recibir su descuento enseñe esta tarjeta en la recepción del dentista.',
    this_is_not_insurance: 'ESTO NO ES UN SEGURO MÉDICO',
    call_customer_care: 'Llame ya',
    membership_id: 'ID de membresía:',
    membership_id_information: 'Use su ID de membresía cuando hable con servicio al cliente',
    membership_id_is_not_available: 'Cargando aún… puede tomar hasta 15 minutos',
    podersalud: 'PODERsalud',
    my_benefits: 'Mis beneficios',
    header_subtitle: 'PODERsalud',
  },
  find_providers: {
    view_map: 'Ver mapa',
    view_list: 'Ver lista',
    call: 'Llamar',
    loading: 'Cargando...',
    providers: 'Proveedores',
    add_favorite_providers: 'Add favorite providers (SP)',
    tap_the_heart_to_add: 'Toque el corazón para añadir a sus favoritos',
    got_it: 'Listo',
    see_your_favorite_providers: 'Proveedores favoritos',
    favorite_providers: 'Proveedores favoritos',
    provider_name_removed_from_favorites: 'Este proveedor ha sido eliminado de sus favoritos.',
    my_favorites: 'Mis favoritos',
    no_providers_found: 'No se encontraron proveedores',
    try_another_zipcode: 'No se encontraron proveedores en la zona. Por favor, intenta con otro código postal.',
    try_again: 'Inténtelo de nuevo',
    specialty: 'Seleccionar Especialidad',
  },
  dental: {
    title: 'Dentista',
    description: 'Grandes ahorros en servicios dentales en miles de ubicaciones.',
    descriptionDesktop: 'Grandes ahorros en servicios dentales en miles de ubicaciones.',
    save_on: 'Grandes ahorros en servicios dentales en miles de ubicaciones',
    information: 'Cuide la salud de su boca, dientes y encías con ahorros en más de 262,000<sup>1</sup> consultorios dentales en todo el país.',
    htmlContent: '<ul><li>Aproveche descuentos del 15-50%<sup>2</sup> en limpiezas, rayos-x, coronas y otros procedimientos dentales — incluyendo tratamientos especiales.</li><li>Reciba atención de alta calidad a la vez que ahorra considerablemente en sus gastos dentales.<sup>2</sup></li><li>Los ahorros reales pueden variar según el proveedor, el servicio y la ubicación geográfica.</li></ul>',
    how_to_use: 'Cómo utilizarlo:',
    how_to_use_steps: '<ol><li>Busque un dentista en nuestra red cercano a usted seleccionando la opción “Encontrar dentista” a continuación.</li><li>Utilice el mapa para buscar consultorios dentales en su área.</li><li>Elija un dentista.</li><li>Llame al consultorio y verifique que acepten la <strong>lista de tarifas de Aetna Dental Access PPO (Organización de Proveedores Preferidos).</strong> Asegúrese de hacerlo antes de su visita.</li><li>Se espera que todos los consultorios dentales acepten la <strong>lista de tarifas de Aetna Dental Access PPO.</strong> Si encuentra algún problema, por favor comuníquese con nuestro equipo de Atención al Cliente al (726) 202-1213. Durante la llamada, seleccione la opción 2 para recibir atención en español.</li><li>Pograme una cita.</li><li>Antes de recibir la consulta o el procedimiento, pregunte al dentista por el precio y muestre su tarjeta de membresía —ya sea la tarjeta física o la tarjeta digital— para que el consultorio pueda verificar su cuenta.</li><li>Si enfrenta algún desafío durante su consulta, por favor comuníquese con el equipo de Atención al Cliente lo antes posible al número (726) 202-1213. Su satisfacción es nuestra prioridad.</li></ol>',
    important: '<strong>Nota importante:</strong> Si el consultorio dental no está familiarizado con o no acepta la <strong>lista de tarifas de Aetna Dental Access PPO</strong>, por favor comuníquese de inmediato con el equipo de Atención al Cliente al (726) 202-1213. Estamos aquí para ayudarle a resolver cualquier problema que pueda surgir.',
    disclaimer: '<p><sup>1</sup>Con base en datos de octubre de 2019.</p><p><sup>2</sup>Los costos y ahorros reales varían según el proveedor y el área geográfica</p>',
    membershipCard: {
      title: 'Tarjeta de beneficios dentales',
      subtitle: 'Para recibir su descuento enseñe esta tarjeta en la recepción del dentista.',
    },
    find_a_dentist: 'Encontrar dentista',
    providers_found_for_your_location: 'dentistas encontrados en la ubicación seleccionada',
    save_your_favorite_providers: 'Guarde sus dentistas',
    faq: {
      items: [
        {
          title: '¿Qué sucede si mi dentista no es un proveedor participante?',
          htmlContent: "Recomendar un proveedor a través de la aplicación, en línea o llamando al soporte técnico de los miembros. Envíe la información del proveedor y Aetna se pondrá en contacto con el proveedor para unirse a la red.",
        },
        {
          title: '¿Puedo recibir un descuento en cualquier consultorio dental?',
          htmlContent: 'No. Para recibir su descuento, debe ir a un proveedor participante. Encuentre proveedores en su aplicación móvil, en línea, o llame de lunes a viernes, de 7am a 7pm CT, sábados de 8am a 5pm, para preguntas o ayuda a localizar un proveedor.',
        },
        {
          title: '¿Cuántas veces puedo usar el beneficio dental?',
          htmlContent: 'No hay límite en la cantidad de veces que se puede usar este beneficio.',
        },
      ],
    },
  },
  telemedicine: {
    title: 'Telemedicina',
    description: 'Consultas médicas a una llamada o un clic de distancia 24/7.',
    descriptionDesktop: 'Consultas médicas a una llamada o un clic de distancia 24/7.',
    save_on: 'Consultas médicas a una llamada o un clic de distancia 24/7',
    information: "Acceda a médicos certificados 24/7 desde donde esté sin cargos adicionales por consulta. Consulte al médico para recibir un diagnóstico, opciones de tratamiento y medicamentos, en caso de requerirlo.",
    htmlContent: '<ul><li>Tratamiento para enfermedades comunes como resfriados, gripes y más.</li><li>Tiempo de respuesta aproximado de 10 minutos.</li><li>Médicos certificados con un promedio de 20 años de experiencia.</li></ul>',
    how_to_use: 'Cómo utilizarlo:',
    how_to_use_steps: '<ol><li>Espere 24 horas después de inscribirse antes de tratar de hacer su primera cita.</li><li>Haga clic en el botón “ Solicitar consulta online” que se muestra a continuación para registrarse.</li><li>Será redireccionado para iniciar sesión en su cuenta de Teladoc (puede tomar hasta 1 minuto).</li><li>Siga las instrucciones que le aparecen en pantalla para registrar su cuenta en Teladoc. Si la pantalla muestra contenido en inglés, haga clic en el botón que aparece en la parte superior derecha de la pantalla para cambiar el idioma al español.</li><li>Después de registrar su cuenta en Teladoc, se le pedirá que rellene su historial médico.</li><li>Agregue una farmacia a su cuenta, la cual será utilizada para surtir sus recetas médicas.</li><li>Add a pharmacy to your account that will be used for filling any prescriptions.</li><li>Para agendar una consulta médica, haga clic en el botón “Hablar con un médico” y siga las instrucciones que le aparecen en pantalla.</li><li>Si enfrenta algún desafío durante su consulta, por favor comuníquese con el equipo de Atención al Cliente lo antes posible al número (855) 847-3627. Su satisfacción es nuestra prioridad.</li></ol>',
    important: '<strong>Nota importante:</strong> Si encuentra algún inconveniente, por favor contacte inmediatamente al equipo de Atención al Cliente al (855) 847-3627. Estamos aquí para ayudarle a resolver cualquier problema que pueda surgir.',
    faq: {
      items: [
        {
          title: '¿Hablo con médicos de verdad?',
          htmlContent: 'Sí. Hablará con médicos que son internistas certificados por la junta de los Estados Unidos, médicos de familia con licencia estatal y pediatras con licencia para ejercer la medicina en los Estados Unidos y que viven en los EE.UU. Cuando solicite una visita, Teladoc lo conectará con un médico con licencia en su estado.',
        },
        {
          title: '¿Puedo llamar a Teladoc fuera de los Estados Unidos?',
          htmlContent: 'No. Las visitas a Teladoc no están disponibles fuera de los Estados Unidos.',
        },
        {
          title: '¿Mi registro médico electrónico se mantiene en privado?',
          htmlContent: 'Teladoc emplea métodos de cifrado robustos para proteger su información personal. Usted determina quién puede ver la información en su record.',
        },
        {
          title: '¿Cómo se envían las recetas a la farmacia?',
          htmlContent: 'Teladoc no dispensa medicamentos recetados. Si el médico le receta medicamentos, se envía electrónicamente o por teléfono a su farmacia.',
        },
        {
          title: '¿Cuáles son algunas de las afecciones comunes que Teladoc trata?',
          htmlContent: 'Condiciones comunes incluyen problemas sinusales, infecciones respiratorias, alergias, infecciones del tracto urinario, síntomas de resfriados y gripe y muchas otras enfermedades que no son de emergencia.',
        },
        {
          title: '¿Puede Teladoc manejar mis situaciones de emergencia?',
          htmlContent: 'Teladoc está diseñado para manejar problemas médicos no emergentes. No debe usarlo si está experimentando una emergencia médica.',
        },
        {
          title: '¿Puedo solicitar un médico en particular?',
          htmlContent: 'No. Teladoc está diseñado para apoyar su relación con su médico existente. No es un medio para establecer una relación exclusiva con un médico de Teladoc. Por favor, sepa que todos los médicos de Teladoc están altamente calificados y pasan por una rigurosa capacitación y credencialización.',
        },
        {
          title: '¿Puedo obtener una receta?',
          htmlContent: 'Teladoc no garantiza recetas. Depende del médico recomendar el mejor tratamiento. Los médicos de Teladoc no emiten recetas para sustancias controladas por la DEA, no terapéuticas y/o ciertos otros medicamentos que pueden ser perjudiciales debido a su potencial de abuso. Estos incluyen, pero no se limitan a, medicamentos antidepresivos como Cymbalta, Prozac y Zoloft. Según los protocolos de tratamiento, los médicos no pueden recetar un antibiótico para enfermedades virales como la mayoría de los resfriados, dolor de garganta, tos, infecciones sinusales y gripe. Los médicos pueden sugerir opciones de tratamiento alternativas, como receta para aliviar los síntomas o medicamentos de venta libre. Además, Teladoc. no prescriben medicamentos no terapéuticos como Viagra y Cialis.',
        },
      ],
    },
  },
  vision: {
    title: 'Visión',
    description: 'Ahorre en anteojos, contactos, cirugía láser y exámenes.',
    descriptionDesktop: 'Ahorre en anteojos, contactos, cirugía láser y exámenes.',
    save_on: 'Ahorre en anteojos, contactos, cirugía láser y exámenes',
    benefits: [
      {
        eyewear: {
          title: 'Lentes y exámenes',
          save_on: 'Ahorre de 10% a 60% en anteojos y exámenes',
          information: 'Obtenga ahorros significativos para usted y su familia con Coast To Coast Vision™ en más de 20,000<sup>1</sup> proveedores de servicios de visión en todo el país.',
          htmlContent: '<ul><li>Aproveche descuentos del 20%-60%<sup>2</sup> en anteojos recetados incluyendo la mayoría de los marcos, lentes y artículos especiales tales como tintes, recubrimientos y protección UV.</li><li>Reciba 10%-30%<sup>2</sup> de descuento en exámenes visuales.</li><li>Las cadenas participantes incluyen LensCrafters®, Pearle Vision®, Visionworks, JCPenney Optical, Sears Optical, Target Optical® y más.</li></ul>',
          how_to_use: 'Cómo utilizarlo:',
          how_to_use_steps: "<ol><li>Busque un proveedor de servicios de visión seleccionando la opción “Encontrar proveedor” a continuación.</li><li>Elija un proveedor de servicios de visión.</li><li>Llame al proveedor de servicios de visión y verifique que acepten Coast To Coast Vision™. Asegúrese de hacerlo <strong>antes</strong> de su visita.</li><li>Se espera que todos los proveedores de servicios de visión acepten Coast To Coast Vision™. Si encuentra algún problema, por favor comuníquese con nuestro equipo de Atención al Cliente al (726) 202-1213. Durante la llamada, seleccione la opción 2 para recibir atención en español.</li><li>Pograme una cita.</li><li>Antes de recibir la consulta o el procedimiento, pregunte al proveedor por el precio y muestre su tarjeta de membresía —ya sea la tarjeta física o la tarjeta digital— para que el consultorio pueda verificar su cuenta.</li><li>Si enfrenta algún desafío durante su consulta, por favor comuníquese con el equipo de Atención al Cliente lo antes posible al número (726) 202-1213. Su satisfacción es nuestra prioridad.</li></ol>",
          important: '<strong>Nota importante:</strong> Si el proveedor no está familiarizado con o no acepta Coast To Coast Vision™, por favor comuníquese de inmediato con el equipo de Atención al Cliente al (726) 202-1213. Estamos aquí para ayudarle a resolver cualquier problema que pueda surgir.',
          disclaimer: '<p><sup>1</sup>Datos de octubre de 2019.</p><p><sup>2</sup>Los costos y ahorros reales varían según el proveedor y el área geográfica.</p>',
          membershipCard: {
            title: 'Tarjeta de beneficios de visión',
            subtitle: 'Para recibir su descuento enseñe esta tarjeta en la recepción del consultorio.',
          },
          find_a_provider: 'Encontrar proveedor',
          providers_found_for_your_location: 'proveedores encontrados en la ubicación seleccionada',
          save_your_favorite_providers: 'Guarde sus proveedores',
          faq: {
            items: [
              {
                title: '¿Puedo utilizar este beneficio si ya tengo un seguro de la vista?',
                htmlContent: 'Dependiendo el tipo de seguro, el beneficio puede ser utilizado para reducir los gastos de su bolsillo. Por ejemplo, una vez que el beneficio ha sido agotado, usted puede utilizar su descuento para comprar pares adicionales de lentes.',
              },
              {
                title: '¿Hay un límite en el número de veces que se puede utilizar el beneficio?',
                htmlContent: 'No hay límite en el número de veces que usted o su familia pueden aprovechar los ahorros.',
              },
              {
                title: '¿Puedo usar el beneficio en cualquier tienda minorista?',
                htmlContent: 'No. Para recibir un descuento, debe ir a un proveedor en la red de Coast to Coast Vision.',
              },
              {
                title: '¿Este es un seguro de beneficios?',
                htmlContent: 'No. Este es un programa de descuento en gafas y cuidado de la vista. Usted pagará el precio con descuento en el momento de la compra. No hay reembolsos ni papeleo para file.',
              },
              {
                title: '¿Qué pasa si la tienda está ofreciendo descuentos/rebajas?',
                htmlContent: 'El descuento no puede ser combinado con ningún otro descuento o venta especial.',
              },
              {
                title: '¿Qué hago cuando llegue a la ubicación para obtener mi descuento?',
                htmlContent: 'Asegúrese de mostrar al momento de la compra su tarjeta con el logotipo de Coast to Coast Vision.',
              },
              {
                title: '¿Cómo consigo que mi oftalmólogo entre en la red de Coast to Coast Vision?',
                htmlContent: 'Recomiende a su proveedor a través de la aplicación móvil, en línea o llamando al número que está en la parte posterior de su tarjeta y de el nombre, domicilio y número de teléfono del doctor. Coast to Coast Vision se pondrá en contacto para que se unan a la red.',
              },
              {
                title: '¿Cómo me garantizan los grandes ahorros en los lentes de contacto?',
                htmlContent: 'El mayor ahorro y selección de lentes de contacto se ofrece a través del programa de pedidos por correo. Las lentes de contacto de reemplazo se descuentan entre el 10% y el 40% de su precio.',
              },
            ],
          },
        },
      },
      {
        order: {
          title: 'Lentes de contacto a domicilio',
          save_on: 'Ahorre de 10% a 40% en lentes de contacto ordenado',
          information: "Ahorre de 10% a 40% en lentes de contacto ordenados por correo de America’s Eyewear.",
          htmlContent: '<ul><li>Para pedir u obtener un presupuesto, llame de lunes a viernes, de 7:00 am a 7:00 pm CT y sábados, de 8:00 am a 5:00 pm CT</li><li>Correo electrónico: Mande una copia de su receta a americaseyewear@newbenefits.com o por fax al 972.503.5671</li></ul>',
          externalLinkTitle: 'Prescripción por correo Electrónico',
          faq: {
            items: [
              {
                title: '¿Cómo puedo garantizar me los mayores ahorros en lentes de contacto?',
                htmlContent: 'El mayor ahorro y selección de lentes de contacto se ofrece a través del programa de pedidos por correo. Las lentes de contacto de reemplazo se descuentan entre el 10% y el 40% por debajo de retail.',
              },
              {
                title: '¿Cómo consigo que mi oftalmólogo entre en la red de Coast to Coast Vision?',
                htmlContent: 'Recomiende a su proveedor a través de la aplicación móvil, en línea o llamando al número que está en la parte posterior de su tarjeta y de el nombre, domicilio y número de teléfono del doctor. Coast to Coast Vision se pondrá en contacto para que se unan a la red.',
              },
              {
                title: '¿Qué pasa si la tienda está ofreciendo descuentos/rebajas?',
                htmlContent: 'El descuento no puede ser combinado con ningún otro descuento o venta especial.',
              },
              {
                title: '¿Qué hago cuando llegue a la ubicación para obtener mi descuento?',
                htmlContent: 'Asegúrese de mostrar al momento de la compra su tarjeta con el logotipo de Coast to Coast Vision.',
              },
              {
                title: '¿Puedo usar el beneficio en cualquier tienda minorista?',
                htmlContent: 'No. Para recibir un descuento, debe ir a un proveedor en la red de Coast to Coast Vision.',
              },
              {
                title: '¿Hay un límite en el número de veces que se puede utilizar el beneficio?',
                htmlContent: 'No hay límite en el número de veces que usted o su familia pueden aprovechar los ahorros.',
              },
              {
                title: '¿Puedo utilizar este beneficio si ya tengo un seguro de la vista?',
                htmlContent: 'Dependiendo el tipo de seguro, el beneficio puede ser utilizado para reducir los gastos de su bolsillo. Por ejemplo, una vez que el beneficio ha sido agotado, usted puede utilizar su descuento para comprar pares adicionales de lentes.',
              },
              {
                title: '¿Este es un seguro de beneficios?',
                htmlContent: 'No. Este es un programa de descuento en gafas y cuidado de la vista. Usted pagará el precio con descuento en el momento de la compra. No hay reembolsos ni papeleo para file.',
              },
            ],
          },
        },
      },
      {
        laser: {
          title: 'Cirugía láser',
          save_on: 'Ahorre de 40% a 50% en el costo promedio de la cirugía',
          information: 'Ahorre del 40% al 50% en el costo promedio de cirugía LASIK.',
          htmlContent: '<ul><li>Haga una cita con un proveedor de cirugía LASIK, presente su tarjeta de membresía con el logotipo de Coast to Coast Vision™ y pague el precio descontado</li><li>Llame de lunes a viernes de 7am a 8pm CT, sábados y domingos de 9am a 5pm para obtener ayuda en localizar un proveedor.</li></ul>',
          faq: {
            items: [
              {
                title: '¿Cómo me garantizan los grandes ahorros en los lentes de contacto?',
                htmlContent: 'El mayor ahorro y selección de lentes de contacto se ofrece a través del programa de pedidos por correo. Las lentes de contacto de reemplazo se descuentan entre el 10% y el 40% de su precio.',
              },
              {
                title: '¿Es este seguro de beneficios?',
                htmlContent: 'No. Este es un programa de descuento en gafas y cuidado de la vista. Usted pagará el precio con descuento en el momento de la compra. No hay reembolsos ni papeleo para file.',
              },
              {
                title: '¿Puedo utilizar este beneficio si ya tengo un seguro de la vista?',
                htmlContent: 'Dependiendo el tipo de seguro, el beneficio puede ser utilizado para reducir los gastos de su bolsillo. Por ejemplo, una vez que el beneficio ha sido agotado, usted puede utilizar su descuento para comprar pares adicionales de lentes.',
              },
              {
                title: '¿Hay un límite en el número de veces que se puede utilizar el beneficio?',
                htmlContent: 'No hay límite en el número de veces que usted o su familia pueden aprovechar los ahorros.',
              },
              {
                title: '¿Puedo usar el benéfico en cualquier tienda minorista?',
                htmlContent: 'No. Para recibir un descuento, debe ir a un proveedor en la red de Coast to Coast Vision.',
              },
              {
                title: '¿Qué hago cuando llegue a la ubicación para obtener mi descuento?',
                htmlContent: 'Asegúrese de mostrar al momento de la compra su tarjeta con el logotipo de Coast to Coast Vision.',
              },
              {
                title: '¿Qué pasa si la tienda está ofreciendo descuentos/rebajas?',
                htmlContent: 'El descuento no puede ser combinado con ningún otro descuento o venta especial.',
              },
              {
                title: '¿Cómo hago para que mi oftalmólogo u óptico participen en la red de Coast To Coast Vision?',
                htmlContent: 'Comuníquese al número que se encuentra en el reverso de su tarjeta de membresía y proporcione el nombre, la dirección y el número telefónico de su médico, y Coast To Coast Vision se comunicará con él y le brindará información acerca de cómo convertirse en proveedor.',
              },
            ],
          },
        },
      },
    ],
  },
  rx_valet: {
    title: 'Farmacia',
    description: 'Ahorre desde 10% hasta 85% en medicamentos recetados.',
    descriptionDesktop: 'Ahorre desde 10% hasta 85% en medicamentos recetados.',
    save_on: 'Ahorre desde 10% hasta 85% en medicamentos recetados',
    information: 'Ahorre<sup>1</sup> en sus medicamentos recetados obteniendo grandes descuentos a través de NB Rx<sup>2</sup>.',
    htmlContent: '<ul><li>Obtenga descuentos del 10% al 85%<sup>1</sup> en medicamentos en más de 60,000 farmacias en todo el país.</li><li>Las farmacias participantes incluyen a Walgreens, Target, CVS, y muchas otras cadenas nacionales y regionales independientes.</li></ul>',
    how_to_use: 'Cómo utilizarlo:',
    how_to_use_steps: '<ol><li>Haga clic en el botón “Compare precios” que se muestra a continuación.</li><li>Ingrese el nombre de su medicamento.</li><li>Revise el código postal y actualícelo si es necesario.</li><li>Especifique la frecuencia con la que toma su medicamento, incluyendo la dosis diaria y la duración del tratamiento (si aplica).</li><li>Haga clic en “Encuentre el precio más bajo” para ver los resultados.</li><li>Compare precios y farmacias disponibles, y seleccione la opción que más le convenga.</li><li>Haga clic en “Ver tarjeta de descuento”.</li><li>En la farmacia, presente su tarjeta de descuento al personal para adquirir su medicamento a un precio reducido.</li></ol>',
    important: '<strong>Nota importante:</strong> Si la farmacia que visita no conoce el programa de descuentos o si encuentra algún inconveniente, por favor contacte inmediatamente al equipo de Atención al Cliente al (726) 202-1213. Estamos aquí para ayudarle a resolver cualquier problema que pueda surgir.',
    disclaimer: '<p><sup>1</sup>Los descuentos y ahorros reales varían con base en la farmacia, área geográfica y medicamento(s) recetado(s) adquirido(s).</p><p><sup>2</sup>Los descuentos de NB RX no son un seguro médico.</p>',
    membershipCard: {
      title: 'Tarjeta de beneficios de farmacia',
      subtitle: 'Solo para descuentos - ¡NO ES UN SEGURO MÉDICO! Para recibir su descuento enseñe esta tarjeta junto a su receta médica en la farmacia.',
      information: 'Los descuentos son válidos exclusivamente en farmacias participantes y varían dependiendo del tipo de receta médica y la farmacia a la que vaya.',
      more_information: 'Este programa de descuentos no envía pagos directos a las farmacias. Cada miembro debe pagar por el costo de los medicamentos. No se puede utilizar en combinación con un seguro médico. Puede contactar a nuestro Servicio al Cliente en cualquier momento para preguntas, cancelaciones u obtener más información. Este programa es administrado por la compañía Medical Security Card, LLC, de Tucson, AZ.',
    },
    externalLinkTitle: 'Compare precios',
  },
  mri: {
    title: 'Rayos X',
    description: 'Ahorre en resonancias magnéticas, tomografías y más.',
    descriptionDesktop: 'Ahorre en resonancias magnéticas, tomografías y más.',
    save_on: 'Ahorre en resonancias magnéticas, tomografías y más',
    information: 'Acceda a imágenes médicas de alta calidad, servicios de gastroenterología y pruebas de laboratorio a tarifas con descuento.',
    htmlContent: '<ul><li>Ahorre dinero en resonancias magnéticas, tomografías computarizadas y más.</li><li>Reciba una prueba de laboratorio para la diabetes gratis con servicios de imágenes.</li><li>Realice una prueba de cáncer de colon desde casa con un kit, evitando la necesidad de visitar al médico.</li></ul>',
    how_to_use: 'Cómo utilizarlo:',
    how_to_use_steps: '<ul><li>RadiologyAssist ofrece tarifas descontadas para resonancias magnéticas, tomografías computarizadas, ecografías, radiografías y mamografías en centros de radiología acreditados en todo el país.</li><ul><li>Visite <a href="https://radiologyassist.com/">RadiologyAssist</a> (disponible sólo en inglés) o llame al (855) 346-5152</li><li>Mencione el código MEMBERPERKS para recibir su descuento</li></ul><li>ColonoscopyAssist ofrece tarifas descontadas para colonoscopías, endoscopías, ligaduras de hemorroides y consultas gastrointestinales.</li><ul><li>Visite <a href="https://colonoscopyassist.com/">ColonoscopyAssist</a> (disponible sólo en inglés) o llame al (855) 542-6566</li><li>Mencione el código MEMBERPERKS para recibir su descuento</li></ul><li>LaboratoryAssist ofrece un descuento del 10% en pruebas de laboratorio comunes, disponibles en laboratorios clínicos en todo el país.</li><ul><li>Visite <a href="https://laboratoryassist.com/">LaboratoryAssist</a> (disponible sólo en inglés) o llame al (888) 531-2023</li><li>Mencione el código MEMBERPERKS para recibir su descuento</li></ul><li>Colorectal Screening ofrece un 10% de descuento en kits de detección de cáncer de colon para usar en casa.</li><ul><li>Visite <a href="https://coloncancerscreeningkit.com/">ColonCancerScreeningKit</a> (disponible sólo en inglés) para pedir su kit</li><li>Ingrese el código MEMBERPERKS para recibir su descuento</li></ul></ul>',
    faq: {
      items: [
        {
          title: '¿Cómo pago?',
          htmlContent: 'Pagará al programar la cita. No se debe realizar ningún pago en su cita.',
        },
        {
          title: '¿Cómo utilizar este beneficio?',
          htmlContent: 'Llame de lunes a viernes, de 7am a 7:30 pm CT para encontrar una ubicación participante, solicitar una cita o para obtener información sobre precios. Utilice el código GALAXY para obtener su descuento.',
        },
      ],
    },
  },
  lab: {
    title: 'Análisis clínicos',
    description: 'Ahorros nacionales en servicios de laboratorio.',
    descriptionDesktop: 'Usted tendrá acceso directo a laboratorios clínicos donde podrá ahorrarse entre 10% a 80% en los análisis de rutina.',
    save_on: 'Ahorros nacionales en servicios de laboratorios',
    information: 'Usted tendrá acceso directo a laboratorios clínicos donde podrá ahorrarse entre 10% a 80% en los análisis de rutina.',
    htmlContent: '<ul><li>Las categorías de análisis incluyen alergias, vitaminas, niveles de colesterol, función renal, fertilidad, tiroides y más</li><li>Los resultados son confidenciales y están disponibles en 24 horas para la mayoría de las pruebas</li><li>Ordene por teléfono de lunes a viernes de 7am a 10pm CT, sábado, 8am a 12pm o en línea (proporcione el código: R-NEWB al hacer la orden)</li><li>Los beneficios de laboratorios no están disponibles en MD, NJ, NY y RI.</li></ul>',
    externalLinkTitle: 'Ordenar laboratorios',
    faq: {
      items: [
        {
          title: '¿Cual es el procedimiento de los análisis?',
          htmlContent:'Primero ordene su examen por Internet, a través del chat o por teléfono (lunes a viernes, de 7am a 10pm CT, sábados de 8am a 12pm). Si hace su pedido por teléfono proporcione el código RNEWB. Después imprima el formulario de solicitud y lléveselo cuando vaya a su extracción de sangre. El laboratorio no extraerá su sangre sin la forma*. Allí su sangre será extraída por un flebotomista certificado. Los resultados son recibidos por nuestra oficina generalmente dentro de 24 a 48 horas para la mayoría de los análisis y cargados a su cuenta. *Cuando vaya al laboratorio, solamente lleve el formulario de solicitud y su identificación con usted. Si lleva la solicitud a un laboratorio OTRO del proporcionado a través de este servicio, recibirá una facture de ese laboratorio y usted será responsable por pagar. No lleve OTROS formularios de solicitud. Si va a otro laboratorio que no participe en este programa, también será responsable de la factura que reciba de ellos.',
        },
        {
          title: 'Me siento saludable, así que ¿por qué debería hacerme el análisis?',
          htmlContent: 'Una condición médica grave como enfermedades cardíacas, cáncer de próstata o diabetes pueden existir sin síntomas hasta por dos años. La detección temprana es su mejor defensa. Un simple análisis de sangre puede aumentar las posibilidades de identificar posibles condiciones médicas y establecer una línea de base de sus rangos normales a partir de los cuales se pueden monitorear los análisis futuros.',
        },
        {
          title: '¿Esta prueba será pagada por el seguro?',
          htmlContent: 'Este servicio no somete reclamos a seguros. Algunos planes de seguro tienen un beneficio de bienestar o prevención incluido. El servicio de análisis puede proporcionarle códigos CPT para que pueda solicitar el reembolso usted mismo. No hay garantía de que su reclamación será reembolsada. Póngase en contacto con su proveedor de seguros para que obtenga sus beneficios y opciones de reembolso.',
        },
        {
          title: '¿Necesito ver a mi proveedor de atención médica para hacerme la prueba?',
          htmlContent: "El acceso directo de los análisis permite una mayor participación en la atención médica propia. Su doctor puede referir a un laboratorio participante, pero no es un requisito. Sin embargo, se le pedirá que proporcione el nombre específico de la prueba que desea ordenar. Los laboratorios no pueden hacer ninguna recomendación de análisis. Los laboratorios participantes son certificados por CLIA y regulados por agencias gubernamentales.",
        },
        {
          title: '¿Qué es un centro de servicio al paciente?',
          htmlContent: 'Centros de Servicio al paciente son laboratorios donde los pacientes tienen sangre extraída. Estos centros están atendidos por flebotomistas certificados y son los mismos laboratorios referidos por los doctores.',
        },
        {
          title: '¿Necesito una cita?',
          htmlContent: 'Por lo general, no se requiere una cita en la mayoría de los Centros de Servicio al Paciente. Se recomienda llamar a la ubicación para confirmar sus horas de operación y que no se requiera una cita.',
        },
        {
          title: '¿Podré entender los resultados?',
          htmlContent: 'Todos los resultados de los análisis incluyen los rangos de referencia normales, con anomalías indicadas. Se recomienda que busque un doctor para hablar de los resultados fuera de los rangos normales. Para obtener ayuda en comprender major las pruebas y los resultados de laboratorio, visite  labtestsonline.org.',
        },
        {
          title: '¿Cuándo recibiré los resultados?',
          htmlContent: 'Vea las descripciones de los análisis para el plazo de entrega de cada análisis. La mayoría de los resultados están disponibles en 24 a 48 horas después de extraer la sangre. Recibirá un email cuando sus resultados se hayan cargado en su cuenta.',
        },
        {
          title: '¿Mi doctor recibirá una copia de mis resultados?',
          htmlContent: 'Su privacidad es respetada y permanecerá confidencial. Usted es el único que recibirá los resultados a menos que especifique lo contrario por escrito. Puede pedir que se envié una copia de sus resultados a su doctor firmando el formulario de liberación de HIPPA que nos da autorización para hacerlo.',
        },
      ],
    },
  },
  hearing: {
    title: 'Ayuda auditiva',
    description: 'Encuentre su solución auditiva ideal en las tiendas minoristas.',
    descriptionDesktop: 'Encuentre su solución auditiva ideal en las tiendas minoristas.',
    save_on: 'Encuentre su solución auditiva ideal en las tiendas minoristas',
    information: 'Know your numbers with direct access to over 1,500 major clinical laboratories nationwide to save you 10% to 80% on typical costs for lab work.',
    faq: {
      items: [
        {
          title: '¿Cómo encuentro proveedores participantes para el cuidado auditivo minorista de Amplifon?',
          htmlContent: "Busque proveedores cerca de usted a través de la aplicación o en línea o llamando de lunes a viernes, de 7:00 a. M. A 7:00 p. M., Hora central.",
        },
        {
          title: '¿Cómo funciona el programa?',
          htmlContent: 'Cuando llame, un defensor le explicará el proceso de Amplifon, solicitará su información de correo y lo ayudará a programar una cita con un proveedor de atención auditiva. Amplifon le enviará información a usted y al audioprotesista. Esto asegurará que su programa Amplifon esté activado.',
        },
        {
          title: '¿Cuántas veces al año puedo usar mi descuento auditivo?',
          htmlContent: 'No hay límite en el número de veces que puede utilizar su tarjeta de descuento para cualquier beneficio.',
        },
        {
          title: '¿El descuento de atención auditiva minorista es el mismo en todas las ubicaciones participantes?',
          htmlContent: 'Sí, tenemos una red nacional de más de 5,100 proveedores de atención médica auditiva.',
        },
        {
          title: '¿Ofrece más de una marca de audífonos?',
          htmlContent: 'Si. Ofrecemos más de 2000 modelos de audífonos de fabricantes líderes como Oticon, Phonak y Starkey.',
        },
      ],
    },
    benefits: [
      {
        retail: {
          title: 'Atención auditiva',
          save_on: 'Encuentre su solución auditiva ideal en tiendas',
          information: 'Deje que Amplifon le ayude a encontrar la solución de audífono adecuada que se adapte a su estilo de vida a través de un servicio personalizado y productos excepcionales para cada presupuesto.',
          htmlContent: '<ul><li>Ahorro promedio del 64% sobre el precio minorista sugerido por el fabricante para audífonos</li><li>Un año de atención de seguimiento gratuita para limpiezas y chequeos con la compra</li><li>Suministro gratuito de baterías desechables para dos años o una estación de carga para mantener a los miembros con energía</li><li>Garantía de tres años por pérdida, reparación o daño</li><li>Garantía de devolución del 100% del dinero durante la prueba sin riesgo de 60 días de los miembros</li><li>Ofreciendo fabricantes líderes como Miracle Ear, Phonak, Starkey, Oticon, ReSound, Signia, Widex y Unitron.</li></ul>',
          faq: {
            items: [
              {
                title: '¿Cómo encuentro proveedores participantes para el cuidado auditivo minorista de Amplifon?',
                htmlContent: "Busque proveedores cerca de usted a través de la aplicación o en línea o llamando de lunes a viernes, de 7:00 a. M. A 7:00 p. M., Hora central.",
              },
              {
                title: '¿Cómo funciona el programa?',
                htmlContent: 'Cuando llame, un defensor le explicará el proceso de Amplifon, solicitará su información de correo y lo ayudará a programar una cita con un proveedor de atención auditiva. Amplifon le enviará información a usted y al audioprotesista. Esto asegurará que su programa Amplifon esté activado.',
              },
              {
                title: '¿Cuántas veces al año puedo usar mi descuento auditivo?',
                htmlContent: 'No hay límite en el número de veces que puede utilizar su tarjeta de descuento para cualquier beneficio.',
              },
              {
                title: '¿El descuento de atención auditiva minorista es el mismo en todas las ubicaciones participantes?',
                htmlContent: 'Sí, tenemos una red nacional de más de 5,100 proveedores de atención médica auditiva.',
              },
              {
                title: '¿Ofrece más de una marca de audífonos?',
                htmlContent: 'Si. Ofrecemos más de 2000 modelos de audífonos de fabricantes líderes como Oticon, Phonak y Starkey.',
              },
            ],
          },
        },
      },
      {
        home: {
          title: 'Audífonos a domicilio',
          description: 'Smile brighter with big savings at over 262,000* available dental practice locations nationwide',
          save_on: 'Ahorre en soluciones auditivas',
          information: 'Hearing Assist, la marca número uno de audífonos directos al consumidor, brinda acceso y asequibilidad a la industria de los audífonos con soluciones diseñadas para amplificar las frecuencias más comúnmente asociadas con la pérdida auditiva leve a moderada, enviadas directamente a su hogar.',
          htmlContent: "<ul><li>Descuento de $100 de descuento en el precio más bajo anunciado por par de audífonos en los principales minoristas o $50 de descuento en un solo dispositivo*</li><li>Salve un 25% de descuento en cualquier accesorio auditivo**</li><li>Mejoy una tarifa plana de $99 por año para el plan de protección Peace of Mind**</li><li>Incluye una garantía de devolución de dinero de 60 días</li><li>El envío es gratis</li><li>No se necesita receta médica ni un examen costoso para comprar su solución auditiva</li><li>Sujeto a cambios  **El descuento solo se aplica cuando se compra un audífono</li></ul>",
          externalLinkTitle: 'Comprar audífonos',
          faq: {
            items: [
              {
                title: '¿Cómo funciona el programa?',
                htmlContent: "Llame o visite el sitio web para encontrar la solución de audífono adecuada para usted y pague el precio con descuento al momento de pagar. Sus soluciones auditivas se entregarán directamente a su hogar con envío gratuito.",
              },
              {
                title: '¿Cómo sé si los audífonos de entrega a domicilio me quedarán bien?',
                htmlContent: 'Los audífonos Hearing Assist son de talla única. Algunos modelos funcionan con una aplicación descargable y pueden ajustarse automáticamente a sus necesidades individuales. Si bien nuestros audífonos están diseñados para funcionar para la mayoría, entendemos que no funcionarán para todos, por lo que le damos 60 días para probarlos, sin riesgos.',
              },
              {
                title: '¿Puedo usar mi HSA para comprar audífonos?',
                htmlContent: '¡Sí! Los audífonos Hearing Assist son una compra calificada a través de Cuentas de ahorros para gastos médicos y gastos flexibles.',
              },
              {
                title: '¿El descuento solo funciona en la compra inicial?',
                htmlContent: '¡No! Puede aplicar el descuento a cada compra de dispositivo, no solo a la primera.',
              },
              {
                title: '¿A quién llamo si necesito ayuda con mis audífonos?',
                htmlContent: 'Puede comunicarse con el servicio de atención al cliente de EE. UU. Al 800.280.4050, de lunes a sábado, de 7:00 a.m. a 10:00 p.m., Hora central, y domingos, de 8:00 a.m. a 5:00 p.m., Hora central.',
              },
            ],
          },
        },
      },
    ],
  },
  diabetic: {
    title: 'Diabetes',
    description: 'Suministros para diabéticos con descuento a domicilio.',
    descriptionDesktop: '¡Obtenga un medidor de glucosa en la sangre gratis con su primer pedido y ahorre 10% - 50% en todos los suministros diabéticos!',
    save_on: 'Suministros para diabéticos con descuento enviados',
    information: '¡Obtenga un medidor de glucosa en la sangre gratis con su primer pedido y ahorre 10% - 50% en todos los suministros diabéticos! Con el conveniente programa prepagado en línea, sus suministros serán enviados directamente a su casa. Los servicios incluyen:',
    htmlContent: '<ul><li>Envío gratuito con actualizaciones de información por email o texto</li><li>Programa de pruebas anual o opciones de compra según sean necesarias</li><li>No se requiere receta médica para los suministros de pruebas OTC</li><li>Garantía de satisfacción 100%, si no esta contento, le regresaremos el dinero</li><li>Para activar su cuenta, ingrese su ID de grupo que se encuentra en frente de su tarjeta. Recibirá un email para confirmar su dirección de correo electrónico y completar el registro.</li></ul>',
    externalLinkTitle: 'Compre y ordene',
    faq: {
      items: [
        {
          title: '¿Se requiere una receta médica?',
          htmlContent: 'No, no se requiere receta médica para los suministros para diabéticos OTC.',
        },
        {
          title: '¿Qué es el Programa Anual de Pruebas?',
          htmlContent: 'Al inscribirse en el Programa Anual de Pruebas le otorga mayores ahorros en su suministro para la diabetes. Escoja su programa con base en las veces que usted usa los suministros. Usted también puede comprarlos como sea necesario.',
        },
        {
          title: '¿Puedo pedir suministros por teléfono?',
          htmlContent: 'Sí, llame a Rx Valet de lunes a viernes de 7:00 am a 7:00 pm o sábados de 8:00 am a 1:00 pm.',
        },
      ],
    },
  },
  travel: {
    title: 'Turismo descontado',
    description: 'Disfrutar de descuentos exclusivos en viajes.',
    descriptionDesktop: 'Disfrutar de descuentos exclusivos en viajes.',
    save_on: 'Disfrutar de descuentos exclusivos en viajes',
    information: 'Ofrece descuentos más profundos en hoteles, alquiler de coches, vuelos y actividades en todo el mundo. La mayoría de nuestras ofertas de viaje no están disponibles para el público, lo que significa que estas tarifas exclusivas son mucho más bajas que lo que el consumidor promedio puede encontrar en línea.',
    htmlContent: '<ul><li>Hasta un 60% de descuento en los precios públicos de los hoteles</li><li>Mas de 900 mil hoteles en todo el mundo</li><li>200+ aerolíneas, incluidas las aerolíneas de bajo costo (por ejemplo, Southwest Airlines)</li><li>Contienen parques de Disney, Universal Studios, SeaWorld y otros boletos para parques temáticos</li><li>16,000+ actividades</li><li>No hay cargos o contratos ocultos</li></ul>',
    externalLinkTitle: "Buscar ofertas de viaje",
  },
  general_practice: {
    title: 'Consultas presenciales',
    description: 'Obtenga atención médica presencial por menos dinero.',
    descriptionDesktop: 'Obtenga atención médica presencial por menos dinero.',
    save_on: 'Obtenga atención médica presencial por menos dinero',
    information: 'Ahorre de 10% a 40%<sup>1</sup> en la mayoría de los servicios médicos en más de 340,000<sup>2</sup> consultorios, centros médicos y hospitales participantes. Obtenga la atención que usted y su familia merecen por menos dinero.',
    htmlContent: '<ul><li>Usted y su familia inmediata pueden ahorrar usando la red de ValuePoint by MultiPlan®.</li></ul>',
    how_to_use: 'Cómo utilizarlo:',
    how_to_use_steps: '<ol><li>Busque un dentista en nuestra red cercano a usted seleccionando la opción “Encontrar proveedor” a continuación.</li><li>Utilice el mapa para buscar proveedores en su área.</li><li>Elija a un proveedor.</li><li>Llame al proveedor y confirme que acepten el descuento de <strong>ValuePoint by MultiPlan</strong>. Asegúrese de hacerlo <strong>antes</strong> de su visita.</li><li>Se espera que todos los proveedores acepten el descuento de <strong>ValuePoint by MultiPlan</strong>. Si encuentra algún problema, por favor comuníquese con nuestro equipo de Atención al Cliente al (888) 858-7427. Durante la llamada, seleccione la opción 2 para recibir atención en español.</li><li>Programe una cita.<li>Antes de recibir la consulta o el procedimiento, pregunte al proveedor por el precio de la consulta y muestre su tarjeta de membresía.</li><li>Si enfrenta algún desafío durante su consulta, por favor comuníquese con el equipo de Atención al Cliente lo antes posible al número (888) 858-7427. Su satisfacción es nuestra prioridad.</li></ol>',
    important: '<strong>Nota importante:</strong> Si el proveedor no está familiarizado con o no acepta el descuento de <strong>ValuePoint by MultiPlan</strong>, por favor comuníquese de inmediato con el equipo de Atención al Cliente al (888) 858-7427. Estamos aquí para ayudarle a resolver cualquier problema que pueda surgir.',
    disclaimer: '<p><sup>1</sup>Los costos y ahorros reales varían según el proveedor y el área geográfica.</p><p><sup>2</sup>Datos de octubre de 2019.</p>',
    membershipCard: {
      title: 'Tarjeta de descuentos',
      subtitle: 'Para recibir su descuento enseñe esta tarjeta en la recepción del consultorio.',
    },
    find_a_doctor: 'Encontrar proveedor',
    providers_found_for_your_location: 'proveedores encontrados en la ubicación seleccionada',
    save_your_favorite_providers: 'Guarde sus proveedores',
    faq: {
      items: [
        {
          title: '¿Cuántos proveedores hay en la red?',
          htmlContent: "La red ValuePoint by MultiPlan® ofrece acceso a 275.000 profesionales, 1.300 hospitales y 67.000 instalaciones auxiliares.",
        },
        {
          title: '¿Puedo usar este beneficio junto con el seguro de salud? Si es así, ¿cómo funciona esto?',
          htmlContent: 'Su beneficio de descuento no funciona con el seguro de copago tradicional, a menos que el procedimiento no esté cubierto por su seguro. Sin embargo, puede usar el beneficio de descuento para reducir los gastos de su propio bolsillo en servicios no cubiertos o planes de seguro de tipo de indemnización.',
        },
        {
          title: '¿Hay un copago o deducible?',
          htmlContent: 'No, esto no es seguro. Debe pagar el total con descuento en el momento del servicio.',
        },
        {
          title: '¿Qué pasa si tengo una emergencia?',
          htmlContent: 'En caso de una emergencia llame al 911 o diríjase al departamento de emergencias más cercano. Presente su tarjeta de membresía con el logotipo ValuePoint by MultiPlan® a administration.',
        },
        {
          title: '¿Puede mi familia usar este beneficio?',
          htmlContent: 'Toda familia inmediata, incluido el cónyuge y los dependientes legales, puede usar este beneficio.',
        },
        {
          title: '¿Puedo ir a un proveedor fuera de la red y recibir un descuento?',
          htmlContent: 'No. Esta ventaja solo se aplica a los procedimientos realizados por un proveedor dentro de la red.',
        },
        {
          title: '¿Qué son las instalaciones médicas auxiliares?',
          htmlContent: 'Instalaciones auxiliares consisten en una amplia gama de servicios de atención médica especializada que apoyan la atención primaria. Algunos ejemplos son las pruebas de laboratorio, la radiología, las pruebas genéticas, las imágenes diagnósticas, los centros de rehabilitación, la atención de hospicio, la atención aguda a largo plazo, las instalaciones de enfermería, la atención urgente, así como la terapia física, ocupacional, quiropráctica, de masajes y del habla.',
        },
        {
          title: '¿Cómo puedo saber si mi proveedor está en la red?',
          htmlContent: 'Buscar proveedores participantes en la aplicación o en línea, o llame al Soporte al Miembro de lunes a viernes, de 7:00 am a 5:00 pm Hora Central para obtener ayuda para localizar un provider.',
        },
        {
          title: '¿Puedo recomendar a un proveedor que se una a la red?',
          htmlContent: 'Sí, puede enviar recomendaciones de proveedores a través de la aplicación, en línea o llamando al Servicio de atención al miembro. Envíe la información del proveedor y MultiPlan se pondrá en contacto con el proveedor para unirse a network.',
        },
        {
          title: '¿Cuántas veces al año puedo usar este beneficio?',
          htmlContent: 'Puede usar este beneficio tan a menudo como sea necesario.',
        },
      ],
    },
  },
  doctors_online: {
    title: 'Doctors Online (SP)',
    description: 'Email doctors, dentists and other specialists 24/7 (SP)',
    descriptionDesktop: 'Email doctors, dentists and other specialists 24/7 (SP)',
    save_on: 'Access a doctor 24/7 (SP)',
    information: 'The fast, easy way to get trustworthy health information online. Enjoy 24/7 email access to doctors, pharmacists, psychologists, dentists, dieticians and more to get treatment options and advice you understand. (SP)',
    htmlContent: '<ul><li>Responses within a few hours</li><li>Unlimited, confidential services include your immediate family</li></ul> (SP)',
    message_doctor: 'Message Doctor (SP)',
    view_messages: 'View Messages (SP)',
    ask_a_doctor: 'Ask a Doctor (SP)',
    subject: 'Subject (SP)',
    select_specialist: 'Select specialist (SP)',
    compose_message: 'Compose message (SP)',
    from: 'From: (SP)',
    delivered: 'Enviado',
    no_messages: 'You do not currently have any messages (SP)',
    no_history: "Ask a question. We’ve got the answers you need. (SP)",
    input_placeholder: 'Enviar mensaje...',
    today: 'Hoy',
    yesterday: 'Ayer',
    faq: {
      items: [
        {
          title: '¿Qué servicios están incluidos?',
          htmlContent: 'Puede consultar con un médico o psicólogo certificado por la junta para que lo asista con las opciones de tratamiento y las cuestiones de salud mental, nutrición, estado físico y más.',
        },
        {
          title: '¿Hay algún servicio que Médicos en Línea no proporcione?',
          htmlContent: 'Se excluyen las cuestiones de emergencia y la prescripción de medicamentos.',
        },
        {
          title: '¿Me cobran por cada pregunta nueva?',
          htmlContent: 'No. Puede utilizar este beneficio tan a menudo como sea necesario.',
        },
        {
          title: '¿La información médica que comparto permanecerá confidencial?',
          htmlContent: 'Los registros de salud se mantienen privados y protegidos.',
        },
      ],
    },
  },
  cancellation_policy: {
    title: 'Póliza de cancelación',
    this_is_not_insurance: 'ESTO NO ES UN SEGURO MÉDICO',
    faq: {
      items: [
        {
          title: 'Avisos legales:',
          htmlContent: '<ol><li>Este plan no es una póliza de salud o seguro médico.</li><li>Este plan ofrece descuentos de servicios médicos con ciertos proveedores de salud que forman parte de la red.</li><li>Este plan no realiza pagos directos a los proveedores de servicios médicos.</li><li>El miembro del plan está obligado a pagar por todos los servicios médicos descontados en el momento en que se reciben. Los descuentos pueden variar con base en el servicio, proveedor y área geográfica.</li></ol>',
        },
        {
          title: 'Póliza de Cancelación:',
          htmlContent: 'Para cancelar su membresía, por favor llame al (726) 202-1213 o envíe por correo postal una solicitud de cancelación dirigida a Member Services P.O. Box 803475, Dallas, TX 75367-1309. Esta carta debe incluir su nombre, número de identificación de membresía y ser enviada por lo menos tres (3) días antes de su próxima fecha de pago. Este acuerdo puedo ser cancelado por falta de pago. Si una membresía es cancelada por cualquier razón que no sea falta de pago, el o la titular recibirá un reembolso prorrateado de todos los cargos periódicos.',
        },
        {
          title: 'Garantía de reembolso en 30 días:',
          htmlContent: 'Si cancela por cualquier razón durante los primeros 30 días después de la fecha en que se hace efectivo su plan, recibirá el reembolso completo del pago hecho por la membresía.',
        },
        {
          title: 'Resolución de quejas:',
          htmlContent: 'Si tiene problemas o reclamos relacionados con el uso de su membresía, por favor llame al (726) 202-1213 para obtener ayuda. Su queja será investigada y en un plazo de 3 días se le dará una resolución.',
        },
      ],
    },
  },
  podersalud: {
    how_podersalud_helps_you: '¿Para qué sirve PODERsalud?',
    get_my_membership: 'Obtener mi membresía',
    telehealth : {
      title: 'Telemedicina cuando quiera',
      description: 'Sabemos que lleva una vida ocupada. Consulte al médico 24/7 por teléfono o videollamada.',
      items: [
        {
          text: 'Atención en español',
        },
        {
          text: 'Disponible 24/7',
        },
        {
          text: 'Doctores certificados en EE.UU.',
        },
      ],
    },
    prescription: {
      title: 'Medicamentos descontados',
      description: 'Muestre su tarjeta PODERsalud y ahorre hasta 80% en medicamentos recetados.',
      items: [
        {
          text: 'Encuentre el mejor precio',
        },
        {
          text: 'Disponible en 60,000 farmacias del país',
        },
      ],
    },
    dental: {
      title: 'Descuentos con dentistas',
      description: 'Sin deducibles. Sin copagos. Reciba descuentos de 15% - 50% por consulta en sus citas con dentistas.',
      items: [
        {
          text: 'Participan más de 260,000 dentistas',
        },
        {
          text: 'Limpiezas, frenos, rayos-x, rellenos y más',
        },
      ],
    },
    vision: {
      title: 'Otros descuentos médicos',
      description: 'Mejor vista y diagnóstico. Ahorre de 10% - 60% en el cuidado de la visión y hasta 75% en resonancias.',
      items: [
        {
          text: 'Visite un proveedor de la red y presente su tarjeta',
        },
        {
          text: 'Incluye médicos de barrio',
        },
      ],
    },
    earnings: {
      title: 'Recompensas exclusivas',
      description: '¡Gane más! Acceda a las encuestas exclusivas del Grupo de Opinión.',
      items: [
        {
          text: 'Acceda encuestas exclusivas en la app',
        },
        {
          text: 'Remuneración especial',
        },
      ],
    },
  },
  savings: {
    in_savings: '${{ price }} en ahorros',
    with_podersalud: 'Con <span>PODER</span>salud',
    dental: {
      title: 'Dentistas',
      description: '<span>Costo promedio</span> de limpieza dental para un adulto o un niño',
      per_visit: '<span>${{ price }}</span> / por visita',
    },
    telemedicine: {
      title: 'Telemedicina',
      description: '<span>Costo promedio</span> de una cita médica en presencial',
      per_visit: '<span>${{ price }}</span> / por visita',
    },
    pharmacy_lisinopril: {
      title: 'Farmacia',
      description: '<span>Costo promedio</span> de Lisinopril (presión arterial)',
      per_visit: '<span>${{ price }}</span> / 20mg (30 tabletas)',
    },
    pharmacy_amlodipine : {
      title: 'Farmacia',
      description: '<span>Costo promedio</span> de Amlodipino (Presión Arterial)',
      per_visit: '<span>${{ price }}</span> / 10 mg (30 tabletas)',
    },
    vision : {
      title: 'Visión',
      description: '<span>Costo promedio</span> de un examen de anteojos',
      per_visit: '<span>${{ price }}</span> / por visita',
    },
  },
  rx_drug_prices: {
    header: {
      title: 'Rx Valet',
      text: 'Busque y compare precios:',
    },
    brand: "Marca",
    generic: "Genérico",
    drug_name: 'Medicamento',
    enter_first_three_letters: 'Ingrese las primeras 3 letras del medicamento',
    zipcode: 'Código postal',
    enter_zip_code: 'Ingresa tu código postal',
    times_per_day: 'Veces por día',
    enter_times_per_day: 'Introduzca horas por día',
    number_of_days: 'Número de días',
    enter_number_of_days: 'Introduzca el número de días',
    update_location: 'Cambiar ubicación',
    find_lowest_price: 'Encuentre el precio más bajo',
    pharmacies: 'Farmacias',
    no_results: 'No hay resultados disponibles',
    search_results: '{{ result }} proveedores cerca de',
    distance: '{{ distance }} millas',
    view_savings: 'Ver tarjeta de descuento',
    package_size: 'PACKAGE SIZE (sp)',
    select_package_size: 'Select package size (sp)',
    drug_card: {
      discount: 'Tarjeta de descuento',
      not_insurance: 'Este no es un seguro.',
      estimated_discount: '*Precio estimado con descuento',
      member_id: 'ID de la membresía:',
      group_id: 'ID del grupo:',
      bin: 'BIN:',
      pcn: 'PCN:',
      customer_service: 'Atención al cliente:',
      pharmacy_help: 'Farmacia:',
    },
  },
  revisions: {
    hearing: {
      title: 'Ayuda auditiva',
      description: 'Encuentre su solución auditiva ideal en las tiendas minoristas.',
      descriptionDesktop: 'Encuentre su solución auditiva ideal en las tiendas minoristas.',
      save_on: 'Encuentre su solución auditiva ideal en tiendas',
      information: 'Deje que Amplifon le ayude a encontrar la solución de audífono adecuada que se adapte a su estilo de vida a través de un servicio personalizado y productos excepcionales para cada presupuesto.',
      htmlContent: '<ul><li>Ahorro promedio del 64% sobre el precio minorista sugerido por el fabricante para audífonos</li><li>Un año de atención de seguimiento gratuita para limpiezas y chequeos con la compra</li><li>Suministro gratuito de baterías desechables para dos años o una estación de carga para mantener a los miembros con energía</li><li>Garantía de tres años por pérdida, reparación o daño</li><li>Garantía de devolución del 100% del dinero durante la prueba sin riesgo de 60 días de los miembros</li><li>Ofreciendo fabricantes líderes como Miracle Ear, Phonak, Starkey, Oticon, ReSound, Signia, Widex y Unitron.</li></ul>',
    },
  },
  membership_materials: {
    download: "Download",
    unavailable: "unavailable",
    book: "Membership booklet",
    card: "Card",
  },
};

export default benefits;
