import dynamicRoutes from '@separate/dynamicRoutes';

function checkPath(url, paths) {
  if (paths.some(path => url.startsWith(path))) return true;
  if (paths.some(path => url.startsWith(`/en${path}`))) return true;
  return false;
}

const PREMIUM_PATHS = [
  '/premium',
  '/benefits',
  '/farmasep',
  '/podersalud',
  '/poderseguros',
];

export function isPremiumPath(url) {
  return checkPath(url, PREMIUM_PATHS);
}

const EARN_PATHS = [
  '/earn',
  '/rewards_center',
  '/survey_center',
];

export function isEarnPath(url) {
  return checkPath(url, EARN_PATHS);
}

const PODERCARD_PATHS = [
  '/podercard',
  'https://link.podercard.com',
  'http://link.podercard.com',
];

export function isPodercardPath(url) {
  return checkPath(url, PODERCARD_PATHS);
}

const JOBS_PATHS = [
  '/jobs',
];

export function isJobsPath(url) {
  return checkPath(url, JOBS_PATHS);
}

export function getSubpaths(path) {
  return path.replace(/^\//, '').replace(/\?.*$/, '').replace(/\/$/, '').split('/');
}

export function createResolver(routes) {
  return (path) => {
    const subpaths = getSubpaths(path);

    function checkLevel(subroutes, levels) {
      if (levels.length === 0) {
        return subroutes['/'] != null;
      }

      const [root, ...rest] = levels;

      const wildcard = subroutes['*'];
      const match = subroutes[root];

      if (match == null && wildcard == null) return false;

      if (match === '') {
        return rest.length === 0;
      }

      if (match === '*') {
        return rest.length === 1;
      }

      if (typeof match === 'object') {
        return checkLevel(match, rest);
      }

      if (wildcard != null) {
        if (typeof wildcard === 'object') {
          return checkLevel(wildcard, rest);
        } else {
          return rest.length === 0;
        }
      }

      return false;
    }

    return checkLevel(routes, subpaths);
  };
}

const resolve = createResolver(dynamicRoutes);

export function isArticle(path) {
  const subpaths = getSubpaths(path);

  return subpaths.length === 3 && subpaths[0] === 'articles' && subpaths[1] !== 'page';
}

export function sepResolve(path, locale) {
  const prefix = locale === 'en' ? '/en' : '';

  console.log("In unrendered, path", path);
  if (isArticle(path)) {
    console.log("In unrendered, is article");
    const [_, category, slug] = getSubpaths(path);
    return `${prefix}/article/${category}/${slug}`;
  } else if (resolve(path)) {
    console.log("In unrendered, is recognized dynamic path");
    return `${prefix}${path}`;
  }
  console.log("In unrendered, matching no rules, falling through");

  return prefix === '' ? '/' : prefix;
}
