const podercard = {
  main: {
    header: {
      banking: "Banking",
      services: "Services"
    },
    subheader: "Ensure financial well-being with affordable, reliable banking. Welcome Tech dba SABEResPODER is not a bank. Banking services provided by Thread Bank, Member FDIC.",
    cta: "Apply Today",
  },
  services: {
    header: "Why People Join",
    items: [
      {
        title: "Mobile Banking for the Community",
        content: "PODERcard Debit is an all-in-one banking platform made for all, no social security number needed.<sup>1</sup>"
      },
      {
        title: "Instantly Accessible",
        content: "Get a virtual card instantly upon approval!<sup>1</sup> No more waiting around for your physical card to arrive in the mail."
      },
      {
        title: "Rewards with Instant Payouts",
        content: "Receive instant cash payouts for sharing your opinion when combined with our Rewards program.<sup>2</sup>"
      },
      {
        title: "Personalized Customer Service",
        content: "We have your back! Our in-depth Help Center and customer success agents are here to support you."
      }
    ],
    cta: "Apply Today"
  },
  chat: {
    header: "Do you have any questions?",
    content: "Our Customer Service Team is happy to assist you! <a href='tel:(726) 202-1213'>(726) 202 1213</a>",
    cta: "Chat with an expert"
  },
  rewards: {
    header: "Banking that cares",
    subheader: "Your path to better and secure banking starts here.",
    items: [
      {
        title: "Making Banking Accessible",
        content: "We value diversity and inclusivity, which is why you can apply with SSN, ITN, or Passport."
      },
      {
        title: "Always Secure",
        content: "With Two-Factor Authentication, your account stays protected at all times."
      },
      {
        title: "Convenient Cash Control",
        content: "Manage direct deposits and  access your funds at an ATM near you, whenever you need it."
      },
      {
        title: "Get the answers you need",
        content: "Access our bilingual customer service. No matter what you need, you'll find trustworthy personalized support."
      }
    ]
  },
  testimonials: {
    header: "Community Voices",
    items: [
      {
        content: "“Many of the banks cannot… or rather, they don't really help you with opening an account and store your money. PODERcard Debit does, they make it <strong>very easy.</strong>“",
        span: "- Leticia L. Los Angeles, CA"
      },
      {
        content: "“I've participated in a number of surveys, 15-20 of them, and I've earned around <strong>$300-$400</strong> and that's been more accessible with the PODERcard Debit.“",
        span: "- Marco V. Chicago, IL"
      },
      {
        content: "“Thanks to PODERcard Debit I can <strong>transfer money</strong> for free to my wife and family back home at no additional charge.“",
        span: "-  Jose M. Miami, FL"
      }
    ]
  },
  articles: {
    header: "Discover Finance Tips",
    cta: "Read Article"
  },
  faq: {
    header: "Frequently Asked Questions",
    items: [
      {
        title: "What is a digital bank?",
        content: "Welcome Tech dba SABEResPODER is not a bank. Banking services provided by Thread Bank, Member FDIC. Digital banks, also called mobile banks or online banks, offer a digital-only bank account that you can access over a computer or a mobile app. They typically do not have physical branches. This doesn't impact the quality of customer experience, in fact it's much the opposite, as this type of bank is more cost-efficient, which means the user gets a better deal. This is why PODERcard Debit is able to offer great features with no minimum balance.<sup>3</sup>",
      },
      {
        title: "Is this safe?",
        content: "Our goal was to make banking more accessible, trustworthy and easy to use. PODERcard Debit meets banking security standards in order to protect your personal and financial information, and you'll always have real-time notifications of any activity on your account. If you need to lock your card for any reason, you can do so in moments from the app.",
      },
      {
        title: "What do I do if I need help?",
        content: "You can easily get in touch with our bilingual customer service team at any stage of your banking journey. Your satisfaction and security are our #1 priority.",
      }
    ],
    more: {
      title: "Do you have any questions?",
      content: "Our Customer Service Team is happy to assist you! <a href='tel:(726) 202-1213'>(726) 202 1213</a>",
      cta: "Chat with an expert"
    },
  },
  disclaimer: {
    title: "Disclaimer",
    items: [
      "Welcome Tech dba SABEResPODER is not a bank. Banking services provided by Thread Bank, Member FDIC. The PODERcard Visa Debit Card is issued by Thread Bank, Member FDIC, pursuant to a license from Visa U.S.A. Inc and may be used everywhere Visa debit cards are accepted. PODERcard account opening is subject to identity verification by Thread Bank.<br/><br/>Your deposits qualify for up to $3,000,000* in FDIC insurance coverage when placed at program banks in the Thread Bank deposit sweep program. Your deposits at each program bank become eligible for FDIC insurance up to $250,000, inclusive of any other deposits you may already hold at the bank in the same ownership capacity. You can access the terms and conditions of the sweep program at <a href='https://go.thread.bank/sweepdisclosure' target='_blank' rel='noopener noreferrer'>https://go.thread.bank/sweepdisclosure</a> and a list of program banks at <a href='https://go.thread.bank/programbanks' target='_blank' rel='noopener noreferrer'>https://go.thread.bank/programbanks</a>. Please contact <a href='mailto:customerservice@thread.bank' target='_blank' rel='noopener noreferrer'>customerservice@thread.bank</a> with questions regarding the sweep program. *See more here: <a href='https://go.thread.bank/sweepdisclosure' target='_blank' rel='noopener noreferrer'>https://go.thread.bank/sweepdisclosure</a>.",
      "Other SaberEsPoder products, services, and discounts are not provided by Thread Bank.",
      "See <a href='https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/blte14859d0ceca95c5/657776d641160e89c93571ec/Welcome_Tech_Thread_Consumer_Deposit_Agreement_2023_11_21_en.pdf' target='_blank' rel='noopener noreferrer'>Deposit Account Agreement</a> for fees and additional details."
    ]
  }
};

export default podercard;
