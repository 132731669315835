const earn = {
  main: {
    header: {
      earning: "Earning",
      opportunity: "Opportunities"
    },
    subheader: "Earn cash rewards by sharing your valuable experiences with us!<sup>1</sup>",
    cta: "Start earning",
  },
  services: {
    header: "Investing in you",
    items: [
      {
        title: "Digital Earnings",
        content: "Earn cash rewards while sharing your valuable experience with us. Surveys cover a variety of topics!"
      },
      {
        title: "Make Your Voice Heard",
        content: "Help companies build more tailored products that truly satisfy the needs of our community. Share your opinion today!"
      },
      {
        title: "Get Paid",
        content: "Receive cash rewards for sharing your opinions. Earn $10 to get a prepaid Rewards Card<sup>2</sup>."
      },
      {
        title: "With You Every Step",
        content: "Our Customer Support Team will provide you with guidance and personalized support through the Rewards experience."
      }
    ],
    cta: "Start earning"
  },
  chat: {
    header: "Do you have any questions?",
    content: "Our Customer Service Team is happy to assist you! <a href='tel:(726) 202-1213'>(726) 202 1213</a>",
    cta: "Chat with an expert"
  },
  rewards: {
    header: "Unlock Rewards",
    subheader: "SABEResPODER makes it easy to earn cash online",
    items: [
      {
        title: "Quick Earning Opportunities",
        content: "Our Rewards program<sup>1</sup> offers surveys of varying lengths and compensates you accordingly. It's that simple!"
      },
      {
        title: "Financial Empowerment for All",
        content: "The Rewards program<sup>1</sup> aims to empower everyone, regardless of their gender, ethnicity or income."
      },
      {
        title: "Rewarding Your Participation",
        content: "Active participation unlocks more earning opportunities!"
      },
      {
        title: "Constantly Evolving For You",
        content: "Access curated offers geared toward our community!"
      }
    ]
  },
  testimonials: {
    header: "Community Voices",
    items: [
      {
        content: "“I recommend Rewards for quick and easy cash. The best part? I answer surveys <strong>straight from my phone!</strong>“",
        span: "- Monica A. Dallas, TX"
      },
      {
        content: "“Love surveys on the PODERcard app! So <strong>easy to get rewards and keep adding money</strong> daily. Great service.“",
        span: "- Diana A. Denver, CO"
      },
      {
        content: "“Reliable and very good reward incentives with <strong>fast payments.</strong>“",
        span: "- Diana A. Denver, CO"
      }
    ]
  },
  articles: {
    header: "Discover Earning Tips",
    cta: "Read Article"
  },
  faq: {
    header: "Frequently Asked Questions",
    items: [
      {
        title: "How much can I be earning?",
        content: "Members can make on average between $0.50 and $5.00 per completed survey. Payment amount depends on the survey length and partner.",
      },
      {
        title: "Why do we get paid for answering surveys?",
        content: "The Latino community continues to grow and our influence grows with it. That’s why brands want to know more about our needs and challenges. Your opinion is incredibly valuable, so you should be paid for it, it’s as simple as that.",
      },
      {
        title: "What topics can I expect to learn about?",
        content: "We offer a variety of topics to learn about, however our focus is on a couple of key areas that impact everyone - finance and healthcare. Everyone needs to earn enough to support their family, and they need to keep them safe and healthy. We know this can sometimes be challenging in the U.S., that’s why we offer incentivized education around these topics.",
      }
    ],
    more: {
      title: "Do you have any questions?",
      content: "Our Customer Service Team is happy to assist you! <a href='tel:(726) 202-1213'>(726) 202 1213</a>",
      cta: "Chat with an expert"
    }
  },
  disclaimer: {
    title: "Disclaimer",
    items: [
      "Refer to the Rewards and Redemption section of our <a href='/en/legal/terms-and-conditions' target='_blank' rel='noopener noreferrer'>terms and conditions</a> for full details of the program offering.",
      "Consult <a href='https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/blt190ee497fa8f036f/632cac654c470a60a9fa4f28/SEP_Rewards_Prepaid_Card.pdf' target='_blank' rel='noopener noreferrer'>Cardholder Agreement for Visa Prepaid Corporate Reloadable Rewards Card</a> for more information."
    ]
  }
};

export default earn;
