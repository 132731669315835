const earn = {
  alternative: 'Alternativa',
  begin: 'Comenzar',
  begin_with_paid_surveys: 'Inscríbase',
  complete: 'Completar',
  completed: 'compeleto',
  courses_completed: 'Módulos completado',
  deposit: 'Sus ganancias serán depositadas a su PODERcard',
  enroll: 'Aplicar',
  enroll_with_paid_surveys: '¡Aplique hoy!',
  learn_and_earn: 'Recompensas',
  learn_earn_spotlight: 'Aprenda + Gane',
  micro_header: 'Otras pruebas',
  micro_view_all: 'Ver más',
  enrollment: {
    earned_rewards: 'Earned Rewards (SP)',
    header_title: 'Start earning today (SP)',
    header_subtitle: 'Building additional income by sharing your opinion and gaining valuable information. (SP)',
    select_payout: 'Select payout method (SP)',
    step: 'Step {{ count }}: (SP)',
    enrollment_steps: {
      additional_info: {
        text: 'Additional Information (SP)',
        origin: 'Are you of Hispanic or Latino/a origin? (SP)',
        race: 'How do you identify? (SP)',
        select_all: 'Select all that apply. (SP)',
        language: 'What is your preferred language for Surveys and SEP content? (SP)',
        paid_surveys: {
          title: 'Are you interested in earning Rewards for completing Paid surveys? (SP)',
          subtitle: 'By opting in to this program, you will receive more frequent earning opportunities via SMS. (SP)',
          review_tos: 'Review Terms of Service (SP)',
        },
      },
      address: {
        text: 'Address (SP)',
      },
      personal_info: {
        text: 'Personal Info (SP)',
        title: 'Basic information (SP)',
        subtitle: 'This is where we’ll ship your Pre-paid card. (SP)',
        cta: 'Update my information (SP)',
      },
    },
    enrollment_success: {
      welcome: 'Welcome, {{ name }}! (SP)',
      title: 'Your Rewards signup is complete. (SP)',
      subtitle: 'We will send your prepaid card once you reach $10 in earnings. Payouts will be processed at the end of each month. (SP)',
      next_step: {
        title: 'Next Step: (SP)',
        subtitle: 'Earn ${{ amount }} (SP)',
      },
      cta: 'Complete my Rewards Profile (SP)',
    },
  },
  preferred: 'Recomendado*',
  prepaid: 'Tarjeta prepagada',
  prepaid_with_paid_surveys: 'Tarjeta prepagada',
  prepaid_text: 'With our SEP Prepaid card, we’ll transfer your earnings to your card at the end of each month, and only once you’ve reached $10. (SP)',
  prepaid_text_with_paid_surveys: 'Reciba pagos tras acumular $10. Los pagos se acreditan al final de cada mes.',
  profile: 'Perfil detallado',
  return_to_earn: "Volver a tablero",
  rewards_earned: 'Ganancias',
  sign_up: 'Obtenga la PODERcard',
  sign_up_with_paid_surveys: 'Regístrese con PODERcard Debit',
  sign_up_text: 'Abra una cuenta y reciba sus recompensas <strong>de inmediato.</strong>',
  sign_up_cta_for_non_panelist: 'Elija un medio de pago',
  sign_up_for_payout_methods: 'Con su membresía SEP puede generar efectivo.',
  sign_up_for_podercard_to_unlock: 'Aplique a PODERcard y reciba sus ganancias casi al instante.',
  spotlight_start: 'Comenzar',
  survey_header: 'Grupo de Opinión',
  survey_view_all: 'Ver más',
  want_to_earn: '¿Quiere hacer dinero extra?',
  want_to_earn_with_paid_surveys: '¿Quiere recibir sus recompensas en efectivo?',
  way_to_pay: "Elija una forma de pago.",
  start_survey_cta: "Comenzar",
  perks: {
    title_1: '¡Ganancias rápidas y fáciles!',
    desc_1: 'Nuestro programa de Recompensas le ofrece oportunidades para ganar dinero mientras aprende o al responder encuestas pagadas. ¡Es así de fácil!',
    title_2: 'Recompensamos su participación',
    desc_2: 'Obtenga recompensas en efectivo al compartir su opinión, participar en un curso educativo, ¡o por simplemente referir a conocidos!',
    title_3: 'Toda la comunidad es bienvenida',
    desc_3: 'Todos los niveles educativos son bienvenidos. No importa si acaba de llegar a EE.UU. o si lleva años viviendo aquí, el programa de Recompensas es para todas y todos.',
  },
  benefits: {
    title_1: 'Aprenda + Gane<span>.</span>',
    desc_1: 'Los cursos de Aprenda + Gane no son sólo para obtener recompensas en efectivo, sino que le ayudarán a tomar decisiones informadas al abrir una cuenta bancaria, contratar servicios médicos, ¡y más!',
    title_2: 'Grupo de Opinión<span>.</span>',
    desc_2: 'Al responder encuestas (¡a cambio de efectivo!), con su opinión ayuda a que las empresas que quieren lanzar productos al mercado para la comunidad sean productos que realmente ayuden. ¡Participe y alce la voz!',
    title_3: 'Referidos (próximamente)<span>.</span>',
    desc_3: 'Quienes aman SABEResPODER naturalmente comparten las virtudes del servicio con conocidos, amistades y familiares. A cambio, le ofrecemos un bono por cada persona referida que se inscriba a nuestros productos.',
    title_4: 'Pagos rápidos<span>.</span>',
    desc_4:'Si cuenta con una PODERcard, ¡podrá ver sus ganancias depositadas a su cuenta en minutos! Si aún no es tarjetahabiente PODERcard, considere enviar su aplicación hoy.',
  },
  faq:{
    items: [
      {
        title: '¿Sobre qué temas puedo aprender?',
        htmlContent: 'Ofrecemos una variedad de temas para aprender, sin embargo resaltamos las áreas que sabemos impactan más a la comunidad: servicios financieros y médicos. Todas las familias necesitan contar con suficientes ingresos para cuidar de su familia, para mantenerla sana y segura. Sabemos que esto puede ser retador en EE.UU., por eso ofrecemos educación remunerada sobre de estos temas.',
      },
      {
        title: "¿Por qué pagan por responder encuestas?",
        htmlContent: "La comunidad sigue creciendo y su influencia también. Por eso hay muchas marcas que quieran entender mejor las necesidades y retos de nuestra comunidad. Su opinión vale mucho, y debería de ser remunerada por ello. Es así de simple.",
      },
      {
        title: "¿Alrededor de cuánto dinero se gana?",
        htmlContent: "Integrantes del Recompensas pueden ganar entre $0.50 y $5.00 en promedio por cada encuesta completada. El monto del pago depende del aliado y de qué tan larga es la encuesta.",
      }
    ]
  },
  landing_page: {
    title: "Recompensas",
    subtitle: "¡Obtenga recompensas en efectivo al compartir su opinión y aprender!",
    earn_button: "Comience a ganar",
    invest: "invierte en usted",
    banking: "Banco digital para la comunidad",
    signup_button: "Enviar solicitud",
    testimonials: "La comunidad dice",
    comment: "He participado en muchas encuestas, unas 15-20, y he ganado unos $300-$400 que han sido depositados directo a mi  PODERcard.",
    faq: "Preguntas frecuentes"
  },
  premium: {
    heading: "Recompensas exclusivas",
    new: "¡Nuevo!",
    cta: {
      heading: "¡Obtenga su membresía PODERsalud!",
      subheading: "Acceda a descuentos médicos y encuestas exclusivas que pagan más.",
      sign_up: "Unirme ya",
    },
    survey: {
      available_for: "Termina en",
      earnable_rewards: "Gane",
      complete: "¡Felicidades! Ganó {{ incentive }} por completar la encuesta exclusiva del mes.",
      next_available: "Próxima encuesta disponible en",
      subheader: "Su membresía le incluye una encuesta exclusiva de mayor ganancia al mes. ¡Aprovéchela antes de que desaparezca!",
      take_survey: "Tomar mi encuesta",
    },
  },
};

export default earn;
