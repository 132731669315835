import { getPrices } from '@separate/utilities/prices';
import { asCurrency } from '@separate/utilities/string';

const prices = getPrices();

export const PREMIUM_ANNUAL_MEMBERSHIP_PRICE = prices?.year ? prices?.year : 15000;
export const PREMIUM_MEMBERSHIP_PRICE = prices?.month ? prices?.month : 1695;
export const PREMIUM_MEMBERSHIP_AS_CURRENCY = asCurrency(PREMIUM_MEMBERSHIP_PRICE / 100);

export const PREMIUM_META_HEAD_IMG_URL = 'https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/bltcf0ea0f58247fdde/62e3e0e719ee366ebba56200/SEP_Membership_Hero_Asset.png';

export const PREMIUM_PLATINUM = 'platinum';
export const PREMIUM_TELEMEDICINE = 'telemedicine';
export const PREMIUM_SILVER = 'silver';
export const PREMIUM_LEGACY = 'legacy';
