const podercard = {
  main: {
    header: {
      banking: "Bancarios",
      services: "Servicios"
    },
    subheader: "Cuenta bancaria digital diseñada para usted. Welcome Tech, que opera como SABEResPODER, no es un banco. Los servicios bancarios son proporcionados por Thread Bank, miembro de la Federal Deposit Insurance Corporation (FDIC).",
    cta: "Solicítela hoy"
  },
  services: {
    header: "Motivos para unirse",
    items: [
      {
        title: "Servicios bancarios para la comunidad",
        content: "PODERcard Debit es una plataforma bancaria integral diseñada para la comunidad, no se necesita número de seguro social<sup>1</sup>"
      },
      {
        title: "Accesible de inmediato",
        content: "Obtenga una tarjeta virtual de inmediato al aprobarse su aplicación.<sup>1</sup> ¡Ya no hay que esperar a recibir una tarjeta física!"
      },
      {
        title: "Recompensas depositadas al instante",
        content: "Comparta su opinión a través de nuestro programa de Rewards ¡y reciba recompensas económicas al instante!<sup>2</sup>"
      },
      {
        title: "Atención al Cliente personalizado",
        content: "¡Su satisfacción es nuestra meta! Reciba soporte personalizado con nuestro Centro de Ayuda y Equipo de Atención al Cliente."
      }
    ],
    cta: "Solicítela hoy"
  },
  chat: {
    header: "¿Tiene alguna pregunta?",
    content: "¡Nuestro Equipo de Atención al Cliente está feliz de ayudarle! <a href='tel:(726) 202-1213'>(726) 202 1213</a>",
    cta: "Iniciar conversación"
  },
  rewards: {
    header: "Tome control de sus finanzas",
    subheader: "El camino a una experiencia bancaria mejorada comienza aquí",
    items: [
      {
        title: "Diseñado para la comunidad",
        content: "Valoramos la diversidad y la inclusión, por eso puede aplicar con SSN, ITIN o pasaporte."
      },
      {
        title: "Siempre seguro",
        content: "Con la autenticación de dos factores, su cuenta se mantiene protegida en todo momento."
      },
      {
        title: "Finanzas sin estrés",
        content: "La mensualidad puede ser eliminada y no se requiere de un depósito inicial."
      },
      {
        title: "Su dinero a su manera",
        content: "Active las notificaciones para estar al tanto de cada transacción en tiempo real."
      }
    ]
  },
  testimonials: {
    header: "Voces de la comunidad",
    items: [
      {
        content: "“Muchos bancos no pueden… o más bien, no quieren ayudarte con abrir una cuenta para guardar tu dinero. PODERcard sí, y hacen que sea <strong>muy fácil.</strong>“",
        span: "- Leticia L. Los Angeles, CA"
      },
      {
        content: "“He participado en muchas encuestas, unas 15-20, y he ganado unos <strong>$300-$400</strong> que han sido depositados directo a mi PODERcard Debit.“",
        span: "- Marco V. Chicago, IL"
      },
      {
        content: "“Gracias a PODERcard Debit puedo <strong>transferir dinero gratis</strong> a mi esposa y familia allá del otro lado sin cargos.“",
        span: "-  Jose M. Miami, FL"
      }
    ]
  },
  articles: {
    header: "Tips para cuidar sus finanzas",
    cta: "Lea más"
  },
  faq: {
    header: "Preguntas frecuentes",
    items: [
      {
        title: "¿Qué es un banco digital?",
        content: "Welcome Tech, que opera como SABEResPODER, no es un banco. Los servicios bancarios son proporcionados por Thread Bank, miembro de la Federal Deposit Insurance Corporation (FDIC). Un banco digital, también conocido como banca móvil o banco en línea, ofrece cuentas bancarias accesibles por medio de una app en el celular. Normalmente no cuentan con tiendas presenciales, sin embargo esto no impacta la calidad ni la agilidad de la atención al cliente. La ventaja de este servicio es que permite ser más eficiente en costos, lo que se traduce a una mejor experiencia para el cliente ya que no es necesario cobrar cuotas mensuales ni cumplir con saldos mínimos.<sup>3</sup>",
      },
      {
        title: "¿Es segura la banca móvil?",
        content: "Nuestra meta es hacer la bancarización más incluyente, confiable y sencilla. PODERcard Debit cuenta con los más altos estándares de seguridad para proteger su información personal y financiera. Podrá optar por recibir notificaciones en tiempo real sobre cualquier actividad en su cuenta. Si necesita bloquear su tarjeta por cualquier motivo, lo podrá hacer al instante desde la app.",
      },
      {
        title: "¿Qué hago si necesito ayuda?",
        content: "Póngase en contacto con nuestro Equipo de Atención al Cliente en cualquier momento. Su satisfacción es nuestra prioridad #1.",
      }
    ],
    more: {
      title: "¿Tiene alguna pregunta?",
      content: "¡Nuestro Equipo de Atención al Cliente está feliz de ayudarle! <a href='tel:(726) 202-1213'>(726) 202 1213</a>",
      cta: "Iniciar conversación"
    }
  },
  disclaimer: {
    title: "Disclaimer",
    items: [
      "Welcome Tech, que opera como SABEResPODER, no es un banco. Los servicios bancarios son proporcionados por Thread Bank, miembro de la Federal Deposit Insurance Corporation (FDIC). La tarjeta PODERcard Visa Debit Card es expedida por Thread Bank, Miembro FDIC, de acuerdo con una licencia de Visa U.S.A. Inc., y puede utilizarse en todos los lugares donde se aceptan tarjetas Visa. La apertura de la cuenta PODERcard está sujeta a una verificación de identidad por Thread Bank.<br/><br/>Sus depósitos son elegibles para ser asegurados por la FDIC hasta $3,000,000* al resguardarse en bancos que forman parte del programa de transferencia automática de depósitos de Thread Bank. Sus depósitos en cada banco que forma parte del programa son elegibles para el seguro de la FDIC hasta $250,000, incluyendo cualquier otro depósito que ya tenga en el banco al cual usted tenga el mismo derecho. Puede acceder los términos y condiciones del programa de transferencia automática de depósitos en <a href='https://go.thread.bank/sweepdisclosure' target='_blank' rel='noopener noreferrer'>https://go.thread.bank/sweepdisclosure</a> (contenido disponible solo en inglés) y el listado de bancos que forman parte del programa en <a href='https://go.thread.bank/programbanks' target='_blank' rel='noopener noreferrer'>https://go.thread.bank/programbanks</a>. Si tiene preguntas sobre el programa, por favor escriba a <a href='mailto:customerservice@thread.bank' target='_blank' rel='noopener noreferrer'>customerservice@thread.bank</a>. *Vea más aquí: <a href='https://go.thread.bank/sweepdisclosure' target='_blank' rel='noopener noreferrer'>https://go.thread.bank/sweepdisclosure</a> (contenido disponible solo en inglés).",
      "Otros productos, servicios o descuentos de SABEResPODER no son proveídos por Thread Bank.",
      "Consulte el <a href='https://assets.contentstack.io/v3/assets/bltd488044897c9abc0/blt120185552d60d489/657776d6463ba55bb7bad0be/Welcome_Tech_Thread_Consumer_Deposit_Agreement_2023_11_21_es.pdf' target='_blank' rel='noopener noreferrer'>Acuerdo de Cuenta de Depósito</a> para mayor información."

    ]
  }
};

export default podercard;
